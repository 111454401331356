<template>
  <div class="home">
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      finished-text="我也是有底线的"
      @load="loadMoreData"
    >
      <van-sticky>
        <div class="record-title flex">
          <div
            class="item"
            :style="{ color: (type == 2 ? colorData.specialColor : ''), fontWeight: (type == 1 ? 500 : '') }"
            @click="changeType(2)"
          >进行中 <div
              class="bg"
              :style="{ background: (type == 2 ? colorData.specialColor : '') }"
            ></div>
          </div>
          <div
            class="item"
            :style="{ color: (type == 3 ? colorData.specialColor : ''), fontWeight: (type == 2 ? 500 : '') }"
            @click="changeType(3)"
          >已结拍 <div
              class="bg"
              :style="{ background: (type == 3 ? colorData.specialColor : '') }"
            ></div>
          </div>
        </div>
      </van-sticky>
      <div class="product-list">
        <div
          class="product"
          v-for="(m, i) in list"
          :key="`i${i}`"
        >
          <div class="item flex align-items-c">
            <div
              class="flex-one flex align-items-c"
              style="overflow: hidden"
            >
              <AuctionList :m="m" />
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount } from "vue";
import { Toast, Dialog, Sticky } from "vant";
import { userBidRecordApi } from "@/api/user";
import { useStore } from "vuex";
import AuctionList from "./modules/AuctionList.vue";

export default {
  components: {
    [Sticky.name]: Sticky,
    AuctionList,
  },
  setup() {
    const store = useStore();

    const type = ref(2);
    const total = ref(0);
    const isLoading = ref(false); // 列表加载控制器
    const hasMore = ref(true); // 是否有更多数据
    const queryParams = ref({
      page: 0,
      limit: 10,
      status: 2,
    });
    const list = ref([]); // 列表

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
    });

    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const load = () => {
      userBidRecordApi(queryParams.value)
        .then((res) => {
          if (queryParams.value.page == 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }

          total.value = res.data.count;

          if (list.value.length >= res.data.count) {
            hasMore.value = false;
          } else {
            hasMore.value = true;
          }
          isLoading.value = false;
        })
        .catch((message) => {
          Toast(message);
          isLoading.value = false;
        });
    };

    const userId = computed(() => {
      return store.getters.uid;
    });

    const changeType = (val) => {
      if (val !== type.value) {
        list.value = [];
        isLoading.value = true;
        hasMore.value = true;
        queryParams.value.page = 1;
        queryParams.value.status = val;
        type.value = val;
        load();
      }
    };

    const loadMoreData = () => {
      // 查看更多
      if (!isLoading.value && hasMore.value) {
        isLoading.value = true;
        queryParams.value.page = queryParams.value.page + 1;
        load();
      }
    };

    return {
      type,
      isLoading,
      hasMore,
      list,
      userId,
      colorData,
      total,
      rightBtnStyle,

      loadMoreData,
      changeType
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f7f7f7;
  padding-bottom: 40px;
}
.product-list {
  margin-top: 20px;
  background: #fff;
  .top {
    .l,
    .r {
      padding: 32px;
    }
    .l {
      color: #999;
    }
    .r {
      color: #000;
    }
  }
}
.product {
  padding: 0 32px 32px 32px;
  .item {
    padding-top: 20px;
    margin-top: 20px;

    &:first-child {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.record-title {
  padding: 0 8px;
  background: #fff;
  .item {
    color: #666666;
    font-size: 28px;
    padding: 0 24px;
    height: 96px;
    line-height: 96px;
    position: relative;
    flex: 1;
    text-align: center;

    .bg {
      position: absolute;
      left: 50%;
      bottom: 18px;
      width: 36px;
      height: 6px;
      border-radius: 4px;
      margin-left: -18px;
    }
  }
}
</style>