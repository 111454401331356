<template>
  <div
    class="flex align-items-c"
    @click="goAuction(m.product.product_id)"
  >
    <div class="img">
      <img
        :src="m.product.image + '!120a'"
        alt=""
      >
    </div>
    <div class="desc">
      <div class="name fw500 ft14 line1">{{ m.product.store_name }}</div>
      <div class="price">
        <span>当前价</span>
        <span
          class="fw500"
          :style="{ color: colorData.specialColor }"
        >{{ formatPriceNumber(m.auction.now_price, 2) }}</span>
        <span
          class="ml10"
          v-if="m.auction.bidUserInfo && m.auction.bidUserInfo.uid"
        >出价人</span>
        <span
          :style="{ color: colorData.specialColor }"
          v-if="m.auction.bidUserInfo && m.auction.bidUserInfo.uid"
        >{{ m.auction.bidUserInfo.uid == userId ? '我' : m.auction.bidUserInfo.nickname }}</span>
      </div>
      <div class="price-b">
        <template v-if="m.auction.auction_status == 2">
          <span v-if="m.auction.auction_type == 1">开拍时间 {{ pTime(m.auction.start_time, '{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
        <template v-else-if="m.auction.end_time">
          <span v-if="m.auction.auction_type == 1">结拍时间 {{ pTime(m.auction.end_time, '{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { parseTime, formatPriceNumber } from "@/utils/util";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isWeiXin } from "@/utils/util";
export default {
  props: {
    m: [Array, Object],
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const pTime = (time, mat) => {
      return parseTime(time * 1000, mat);
    };
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    const userId = computed(() => {
      return store.getters.uid;
    });

    const goAuction = (id) => {
      // 点击冻结列表 跳转
      let link = "/pages/auctionDetail/index?id=" + id;

      const isWx = isWeiXin();
      if (isWx) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: link,
            });
          } else {
            router.push(link);
          }
        });
      } else {
        router.push(link);
      }
    };
    return {
      colorData,
      userId,

      pTime,
      formatPriceNumber,
      goAuction,
    };
  },
};
</script>

<style lang="scss" scoped>
.img {
  width: 160px;
  height: 160px;
  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 8px;
  }
}

.desc {
  flex: 1;
  margin-left: 32px;
  overflow: hidden;
  color: #000;

  .price {
    margin-top: 24px;
    color: #666;
    span {
      margin-right: 10px;
    }
  }

  .price-b {
    margin-top: 22px;
    color: #999;

    b {
      margin-left: 6px;
    }
  }
}
</style>